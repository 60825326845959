import "../../core/src/desktop/dialogs/import/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/import/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VUwW7bMAy95yt4GbAcFNhummXabT+wTxhki7bVypIgybHTYf8+SE4cO3XSFkNPgshHkXx81OZ3+vRS5mWTwJ8VQCe4rymkSfLlxwqgRlHV/nLnwhnJjhRKiX0w5LonTrwIVVHIteVoSa6jJyAIFxYLL7SiUGjZNip4mBSVIsJj4ygUqDzaYH5qnRflkRRaeVR+6jKM85giS0wP2dbEDBUzFNIsXP6uNuc+0nt9sNbrWJxWPtSNFA7MfiWElaVQSKK9Jrv1COpOkbskCTYZUOfXZqETz3pWULZUUGCHQrrI6W3mljt+iAm8NnSkxg4FDtcJdPvf5Lhm/REerljcDiwWWmp7Feex98RhoRVnNqhAajsn8jEWvxQrhXoeIwCK1rqAMVoMCpo8slucxr2Oc+bwM3s2VjQ3Ov62VOyberG6G8USFmZxuVDxYG+YrYQig3iSK7XsY/r35nuVwhlWIMnRd4j3Fv8NOcY+TroeP4K96U+LAOd/xzIuWhddFyuF1PTgtBR8Tv0hI5Id0RKhnOD48/x3hSPOMGfFc2V1qzh9FZm33mt1Uet8anta6wPaSN69V4b8FwSJYb8OaCU7LgsZwFumnBiIv4TC5iFxsyK+L8/uxghGXpMz01GaEU1BYumvPq13q/mTl+txWK6uFh5JFBwFpTvLTDAHPkupOwq14DyIcMIQiwydFsBONTcB5VNQ4GHE/ANIT2ywOQcAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1jzfbfm3';
export var importItem = '_1jzfbfm8';
export var importItemLabel = '_1jzfbfm9';
export var importItemPrefix = '_1jzfbfma';
export var importItemSuffix = '_1jzfbfmb';
export var importModalButtonContainer = '_1jzfbfm7';
export var importModalContainer = '_1jzfbfm0';
export var importModalContent = '_1jzfbfm2';
export var importModalTip = '_1jzfbfm4';
export var importModalTitle = '_1jzfbfm1';
export var importStatusContent = '_1jzfbfm6';
export var link = '_1jzfbfm5';