import "../../core/src/desktop/dialogs/setting/workspace-setting/properties/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/properties/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VV246bMBB9z1fMS6XugyNINjT1ql9SrSoHDzC7xrZsk7Ct+u8VJjfYJEuq9gnsMWfOmTke5j/SZUnZNk/g1wxAkrdKvHEoFLZPM4hPJslhHshoDrlRTa27yI5kqDikSfKpW740PlDxxnKjA+rAIUcd0D3Nfs/mhxzptBzO7M4RKWDt+9MMtexCQlGpD4FDIgArpCRdcshsC0m3UwtXkmYbE4KpOaSZbQeMFncxmpy2FDauunfTBEUaOWijsdsITmhPPfBG5K+lM42WkMwXHlB4HBL8LkUQrKKyUlRW4TkSPn3GYSvcZ8ZEUZBGdgowj7nRUriuJcq4hwHs8t5uR0WPvaKPivoYwWPWET3KjT7SASiMDszTT7wAsroKErANzDqqz7UNwAbH+/06njFbdIUyOw4VSYlRWYQ7BVApsp58tHhFAZm3Io/t2zlhhxz75jRaixrlt+AafL5K2yqRY2WURHepI9ltve+7OU3xRxK+xLSju1xhZzXee3ljXEc5GMshtS14o0iOXNcfuaBqHeGjrUrXlTcdhL/eFi3Ji43CqZJbP0wu/k9JR/Zfj4y7+RcjJV63dX/dpnCK77t921ZJbNxdwo8zbG3b/WUEqEmzvTUWWTLSmfetvUWuYquHMws5IanxxzHyrkRXanH1z3L07XLRIx58e1jHmvvKkX4dmHmqkf960P4B0vC9+FYHAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var divider = '_13gi6vc7';
export var listHeader = '_13gi6vc1';
export var main = '_13gi6vc0';
export var metaList = '_13gi6vc3';
export var propertyDocCount = '_13gi6vc6';
export var propertyIcon = '_13gi6vc4';
export var propertyName = '_13gi6vc5';
export var propertyNameIconEditable = '_13gi6vcc';
export var propertyRequired = '_13gi6vc9';
export var propertyRow = '_13gi6vc2';
export var propertyRowNamePopupRow = '_13gi6vcb';
export var spacer = '_13gi6vc8';
export var subListHeader = '_13gi6vca';