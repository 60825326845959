import "../../core/src/modules/app-sidebar/views/open-in-app-card/open-in-app-card.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/app-sidebar/views/open-in-app-card/open-in-app-card.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51UwW7bMAy95yuEAgOSgwrbc9pUxS77i2EYBtWiE66y5El04nTovw+W7NR2k7bbKZFMvvf4SPH6Z5rcWfV7k7A/C8YeZPG4dbYxSrC9dEvOZVmiAb7PuJZHcPwlgtcOK+mOq/su0ToFjjupsPGCber25VawtG6ZtxrVBVA0HhV8jRgxKaAWjfPWCaaglI2m7qqWSqHZCpZmdcvSZCBqud9JZQ8z3fGSpwFOoa+1PApWaghpW1mftNbWI6E1gpXYgoqoRLYSLL2JIRpKEqyQuliuE7U/MM6yLKnbAH5ARTvB8ryX9MTRKGhnevpbXtva7vsqyUkzcIf/pXUVS64zz0B64Gi4beh+8by4PnXru5Ik+Q6VAvPlilwDVz9CB08APZaWBN+WWZJ8Wk0Q0hD94mbSuZlF6a986n65QgdFVFlY3VSm++LsgQcXg0VjfGEsLYWWnrgtOR1rWMURG7rce/tfozEiygLq+dbmsZ5fjScsj7ywhsBQDOJg1ATocwDaAW53JFh2Mx7h+WCffEtOppXWEPf4BLOOh/vWT0XnU9FodAjttUuNW8ORoPKCFWAI3LsMvoovxuruwcy9JGhp8l5DzqGvdZ0kE3XroO79gj5OV0m3RcPJDk0Zsd2cb+AFE8ZImxnS7T8hjSfktR0frL9fULXFCDtSswlq+rWQ9VvhNF79OUyi3zk0j4LNaYegCy5jYc3I5RH13YQ6n1HnH6Ged0me9/byZjj/6DxJR0OiYOmE4+E8x5tIo/RiXHW+mVY9nOdVj/JVHPszwiB8eWvYNZrHuMy7k4LCOhkdaYwC1z3v+8XzXwUxjcRuBwAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var appIcon = '_109odq8c';
export var appIconCol = '_109odq8b';
export var button = '_109odq83';
export var buttonGroup = '_109odq82';
export var closeButton = '_109odq89';
export var contentCol = '_109odq8a';
export var controlsRow = '_109odq86';
export var link = '_109odq8e';
export var pane = '_109odq81';
export var rememberCheckbox = '_109odq88';
export var rememberLabel = '_109odq87';
export var root = '_109odq80';
export var spacer = '_109odq8d';
export var subtitleRow = '_109odq85';
export var titleRow = '_109odq84';