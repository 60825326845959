import "../../core/src/components/root-app-sidebar/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/root-app-sidebar/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51T227bMAx9z1dwBQY0DwqcW9cpwH5kKAZZom2utiTIdC4b+u+DZaexE7fr9uILeXTIQx4tfqwTna7dEn7PAAzVvlQnCVmJx90MQJWUW0GMVS1Bo2UMbfhnUzNlJ6GdZbQsofZKo0iRD4i2ReTKS3j0x93sZbboa6z+qcaFAcArY8jm8R+SIec6ch7IcCHbDERaCcshaPMR0Ha6u/YtDAXUTM5K0K5sqovEzVjiFr6Bob20ju+/G8VKGNqTwfA0j/SVCjlZkTpmV0lI4BNV3gVWlneXNDt/nbuUePj/PgFSFwwGEZShpr7p/kukfh32ctUd0k2oXZDgHXXLGZzoRPrgnm7Pdo/zx/DY4xi8mYB8jZDMWRYHpLxgCZsk7q4ki6LoY6uk6zECa/qFEvYq3AuhsqzFxfixnkcdrmxljPKMRxY1ameNCq2hSxci+GbAf/f8pJEvilRU9GYTPlA1bqF3Q+iUJostVkO+NPKdB7FMJje87aJujyEr3UFCQcZ07XpXU+eVgKVi2mMsSlb0N2X1kIx3kkqZYuYC9kr6QdzdjelUWruy4UhHtkbuL12q9HMeXGONmJpCWir9HIU7rzTxqdWcbIYN6CvJyecJyWQLDMTvVezpkvmQ3IzIz8Z6NWnij+/doiuvbjuvfsSWk36eGpBvAopDQYzzsbhr3JWTXv4AzW6oT+UFAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var aiUsageBlock = '_30cb3o7';
export var cloudUsageBar = '_30cb3ob';
export var cloudUsageBarInner = '_30cb3oc';
export var cloudUsageBlock = '_30cb3o8';
export var freeTag = '_30cb3od';
export var operationMenu = '_30cb3o5';
export var progressColorVar = 'var(--_30cb3o0)';
export var quickSearch = '_30cb3o3';
export var quickSearchAndNewPage = '_30cb3o2';
export var usageBlock = '_30cb3o6';
export var usageLabel = '_30cb3o9';
export var usageLabelTitle = '_30cb3oa';
export var workspaceAndUserWrapper = '_30cb3o1';
export var workspaceWrapper = '_30cb3o4';