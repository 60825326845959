import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227bOBB991fMBihgA6EhKYmbyihQ7C/svhSLYDGSRjYbitSStGSnyL8vSMqyLF+aFHmyMNdz5kZ/e6ZdqbEiA//GebX4b/fjHn5OAEqtKv8BoGrMud2lEC0nAK8TAKvGqjioXiffTgM+XA8IYDVKUypdpeFToKXvU5Z8moHJUdA0mn9ZzH6R/FKYaB8knl3BuPAxT0P/FsJzVD8A4+dxHc8Hi6Po02mxLiW+ku/xbfmityYbAHudzPdZMu9TK8MtVzKFkm+pcFZcGrJdBzLMn1dabWTBciWUTqFBPWUMy5JLYgN1pQoUwcjnemFcFrQdOXTSYO3tUPIKA4LDIsQPUWWgVLpFXZjlEey582BSSfIEBv5ONrKtVMZFMPwFlSZhAnekjzk57x7sIHL+luIV3NQCdymUgraeq+ArybilyqSQk7SknfjHxlhe7liupCVph6q3VXEI7J8CLXbIn0KFhlkdFEbSY62xKLhcsUxZq6oUSDZTgyUx1ITMM+l0t5BE9fZspnIjBDO5JpJfb6ze0E3I2gVPIYI/eFUrbVHa4wDv6GRnW2JBf7mF/VvVY5/D1QvTk28ynrOMXjjpaTS/T24huoVo/vB4C/Hx6LGSC+GqSenR0AWG+8B3T3ANz7yxDHPLm8Cm4dQyv4R+RJjEig4gv5xjZwQv6E9f8EvkPkM0T+4MEBr6GAqDpO9igC56mo6NlCimPcnZJRqLj+vRdQh4EcLjeyvZF67wIVlf1CiF5ZEgHguSIGh5Ydf7Ve69Pc018dXajnWhBBWX7Lw+CXrc9vocRe7ufdGsgcFd4pc2mHTZDxYtsP1aA2Rqywx/8RubKV24Q6j80SqVtE5FoyPk5RkamvVWbYfiPvL3TzizPbJ4vlgOz4LLDMl97XMcDqkmgW4C3/f4qIa0wB2rUdLwEfKs1lioduRbq9r5dMrO1rPWWPCNSSFOAjS1sY7H4SZdPnvXJiSOoqY9GRMnXZ/MSi8dtK33H3a7Nxxhj47mlUZvFWZGiY31JbaqTiHpqOoQ1XXmt96eMvxvuTYva7Y4HZfF2XG5fwh09YrL/okKXRnkXPmcrdIFyzThcwr+hzmJ37k1t8RMjTmlUGtircba97UhXQrVesGx177HnuLXmxKFoZunk7efu0eaUUPSmvF4HLuOJ2PsixurlpPX/wGxehxhFAwAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyje';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjd';
export var modalContentWrapper = '_1cm6qyjc';
export var modalDescription = '_1cm6qyjg';
export var modalHeader = '_1cm6qyjf';
export var modalOverlay = '_1cm6qyjb';
export var modalVTScope = '_1cm6qyj3';
export var widthVar = 'var(--_1cm6qyj0)';