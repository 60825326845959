import "../../component/src/ui/property/property.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/property/property.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W7W7jKBT9n6dAlUZqpVI5mdTtUI20b7APUPUHNtc2WwIIsJPsqu++MviLJE6Tzqy0P5o6xPdyOfecc3kQzTLJVIL+WSDEuNWC7gkqBOxeFghtuMQV8LJyBH1fab+mleWOK0mQAUEdb8CvUsa4LAla6R1K9M7/7yLabHhrqCao/XxZfCweum1fmaFlSTMBP2+cqeHmzReS18YqQ1BpaHb+9VdGHcV+mcsyStJwyzMuuNsTVHHGQJ5PRTIolIFQgJIOpCPo5uZlCkwmVP4eo0Azq0TtPApx3Qg5pQl6TL61zwIKR1DSPmbKMDDYUMZrS3qYMpq/l0bVkuFciTZNQ80txrQouATcrLCDncNa0BwqJRiYO7+HodIWymxIeBTUwS1O9e4e4cfk2/hOV2/oJ0oeVhYlD0t7j5SmOXf7cakN6Ra7insSrEOpW85cNfkmBM4rKkvo0w9JP4GcFg7M/whxD5SmBqSbx/Y5wraHZpmEXTtsnn8Zm0o1YKakHHbqyh9atJxkWvpX29BCqO3I/DE80Ufa7pYGEXffTymobQSXDgyGBqSzBEklYVLA6rSVeBNg3EAempgrUW98vr9q63ixxwMDrKPGzVvNQS87EhZKOmz533Agm7C+uYvOl+rd8LeemJStDJfvHWdEG94jtOowry0YbEFA7vqTt9ydl6uFXElGzf5uQo5l6hEeMQs2VlGLNyDrk17Ywf55VCCOj/3cUgTdg8GT93zwnw0YQduSPxajw2JtoOGwfUN9AWe3irQ0FP39MnoYtW2XqeClxNzBxhKUQwAAobKdJWkE4fp03pkER5wbfzptFRN6LdOwNIMoz5WMuj6U+OhL9CctTavN5cuMVD1zxh9ACK4t9768rbgDbDXNoSVgGKiX0H8oI/16B4JI/it5nhTcVLN9mbHjpaRQeW2xdysRrFLVrs12ZE4pIZP52Q2eS2ftmCVowgBlSor9z5uCCjtcRrwmZq80xzK+Mhvh9jbo7h51J99yV3F599sk3xX25BMGAlgQxWH/L+np5dY4Dp++1cPNM51waNvfRJJkxgmekG3KILXfINqnzuP6nOcSaMM3B+HP105Df6Dn6EA/rrI2n2B1ONHXYdodXerjOUSv32l9IMtThjbkz3z+YQjGbV6mX7LaE6DnVx3jEhpH1HsM1Jtx4jPHz4OkcyUE1RZYbBAXKmXIxnzY9GpPhQiXeKAWMJdY1e5y+CIkVjEx2LnKJ7dko1x7Rf6RMCjP6X9eb/AlwayjauE1QP8W5+pnwR8bYJwibbh0/o3I744CEPpYfPwL/ma1GSgPAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hide = 'lv10bo1';
export var propertyIconContainer = 'lv10bo4';
export var propertyNameContainer = 'lv10bo2';
export var propertyNameContent = 'lv10bo5';
export var propertyNameInnerContainer = 'lv10bo3';
export var propertyRoot = 'lv10bo0';
export var propertyValueContainer = 'lv10bo6';
export var section = 'lv10bo8';
export var sectionCollapsedIcon = 'lv10bod';
export var sectionContent = 'lv10boe';
export var sectionHeader = 'lv10bo9';
export var sectionHeaderIcon = 'lv10bob';
export var sectionHeaderName = 'lv10boc';
export var sectionHeaderTrigger = 'lv10boa';
export var tableButton = 'lv10bo7';