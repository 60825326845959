import "../../core/src/desktop/dialogs/setting/general-setting/editor/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/general-setting/editor/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWTW/bMAy991foMqA5qEjcpsvU2/7ADrvsqli0zVaWDInOR4f+98FS7NiO67TDdgsoko98j6Rzt61rSM2S/b5hLNNw4Lmze8FWTzeMKfSVlkcRHhrDc+0JsyNPrSEwFB84GNU8lmj4HhUVgq3WyyoElPLQ2pJoe7u5i5Crfwy5vA6ZBMitTF9yZ2ujBNsXSNBEDSNilgIwL0iw++WuaGx2By7Tds+PgsmabC/zfchMcCBOThqfWVcKlsoKSWp8DRCZNcT3p5yPy+UlbJfuIaSrrEdCawRzoCXhDiYZCoQodJBG59TqujSxCZej4VtLZEvBkocByDqAjHw2sf1Qq8dXEGwn3S3nMsvQAI/2cnHRzzr2k1pt3Shml/BAjIfUGiXdcdEr4nHUqdx6q+uoCdlKsJBWQ0bhZxf3NcSdtf/SuLWCrTYnFbfWKXCCraoD81ajuqhMyyM4jsajgu/Bm8egxTmeO6mw9oI9xKzXZzQFQ+CaJ6kxNxwJSn82d11sZrpvFVklETVysI6d9oYs/NSS4NctXy+/LK7Kd/CLz0jVXxj+TlBksedXNLvyYwdOy5ijkkqhyQVLqkM7ZB2PaHSo7kTnJGezLE/r9MrRKDicLks7HKMt+DazapXFBoHDDgx5wYw1wV57cNyDhpTOxvY6CFagUmB6GDLeHXvgvpCqcWmD/oJaAkc42qJtALiYy3eI7LQ48TTaoumL60k6ap5zWQn2OOAwnTzl7fJ1BNXUyHw2fPjIZLJEHeakAIfUm96e5ZLKsBmVdGCoX6wQlZYpFFYrcKHyuVXo+fYZV3OBmFrDK4dlu0G9TpPhpYdp5S408JVMgW+B9gBmRtvLKRzp2yFn08jvf00GiMOZmERtPq081N1Ivneyemq/kWd/0Borj75XWD4s7GPHYVbCnhKjWf9skdOtfmSQQxPtDTLWlVIPrlIyGIv8rjvBVyd0eKw/cvo70OEsFnNQ25poNNWnvw7xw7QZpML/UPXwbHVQz7NQVnoK+/iTJAEH5+xgj1+uFqrRvIxr7M7O2x8VFX2qQAsAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = 'buuecnb';
export var editorWrapper = 'buuecn9';
export var fontItem = 'buuecnf';
export var fontItemContainer = 'buuecne';
export var fontLabel = 'buuecng';
export var menu = 'buuecn2';
export var menuTrigger = 'buuecn3';
export var notFound = 'buuecni';
export var preViewLabelWrapper = 'buuecn1';
export var searchIcon = 'buuecnd';
export var searchInput = 'buuecnc';
export var selectedIcon = 'buuecnh';
export var settingWrapper = 'buuecn0';
export var shapeIndicator = 'buuecna';
export var snapshot = 'buuecn7';
export var snapshotContainer = 'buuecn4';
export var snapshotLabel = 'buuecn8';
export var snapshotSkeleton = 'buuecn6';
export var snapshotTitle = 'buuecn5';
export var spellCheckSettingDescription = 'buuecnj';
export var spellCheckSettingDescriptionButton = 'buuecnk';