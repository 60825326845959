import "../../core/src/desktop/dialogs/setting/general-setting/plans/layout.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/general-setting/plans/layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV227bMAx971dwDwOaYQrsNE1b92W/MQzDwEh0olaWDEnOpUP/fZBkL7YbNx32FIOkDslDHubbMx1LixU5+JXfSfnEcQ2/rwBKa6r4AVCh3UjN1sZ7UxXA8iyrD49XAK9XAN6cj8pSwOvVvMPNYqCQrlZ4LKBUFEHCLxPSEvfS6AK4UU2lg2eDdQGLZcjVg8kjTJtNUekL4Kj4NcvhC+zQXjPmyHupN6wyAhXbYM0Os1lArFGI4EjPpoND7F4Kvz0fFF0xyOzIlsrs2aEAbLzpJ+mYWOSJrc7uTX0ytn1EG0vGXq+L85ShkhvNpKfKFcBJe7LB3DiyzJEi7gvQRtNjnE/DtwxbcjvrluRm6wt4SFW0veZZ9nmQ/ybmZ8yikAfmuDVKMbSEzG+bas06mGWCWSN/3ljTaNHxhmUpNTHJjWaOuNEC7TExVyOX/lhANl/1mSxgK4Ug3a+ygzdWkI3FNK619opd/hNZcblaYG6UsaOSldTPLDpm49a8Re1qtKR9r6wWZOQsjfbMyRcawUf7wc0GHdx+oAPn0fre5nRbdj9iYxWx2sFmndAKyAdRd0nroZh9S/Yqyy4WvkZHkRYVTN2YFotRDfdv0ZcfQI+0jLGz6VF5OvjTdv0dWq+Qh/PEPjXOyzK80J60L8DVyImtye8pbeDE8oy4f3OjMOY7rXhw/hDokXFlGsF20sm1op8w9WI4JR6dtXEyqRjXzqjGRyW/MKlFO9iwi6mifDEQ9rkj1jbdO2aT8t1vpU/zHknwvi/MAvL6AM4oKYbP+wKJKFEjbS/xuzS2gmx+s3JA6Aiy+dJ97dgIjpN95d6Zy+WBtkc4MRS3Kn3lq66VA3NbFOEQDZpIRpbPzsz/9I+IWlaYGjv9nfbrv3VQGrtHK9zlpUhz/8tQS5ZCT9+vY8rRJQ2IQu5G7wdrD59kVRvrMZyn3oqJk1SnZOmq2ePEsThzBt6Ram1lNSFUulxGex3OXZX/vhh/AEV2qAAOCQAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineCloudHeader = '_17iijca9';
export var aiDivider = '_17iijcaa';
export var aiScrollTip = '_17iijcac';
export var allPlansLink = '_17iijca4';
export var cloudScrollTipCaption = '_17iijcae';
export var cloudScrollTipTitle = '_17iijcad';
export var collapsibleHeader = '_17iijca5';
export var collapsibleHeaderCaption = '_17iijca8';
export var collapsibleHeaderContent = '_17iijca6';
export var collapsibleHeaderTitle = '_17iijca7';
export var plansLayoutRoot = '_17iijca0';
export var scrollArea = '_17iijca1';
export var scrollBar = '_17iijca2';
export var scrollThumb = '_17iijca3';