import "../../core/src/modules/doc-info/views/database-properties/doc-database-backlink-info.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/doc-info/views/database-properties/doc-database-backlink-info.css.ts.vanilla.css\",\"source\":\"Ll8xNTJzenlxMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwp9Ci5fMTUyc3p5cTEgewogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBnYXA6IDRweDsKfQouXzE1MnN6eXEyIHsKICBkaXNwbGF5OiBmbGV4Owp9Ci5fMTUyc3p5cTMgewogIG1hcmdpbjogOHB4IDA7Cn0KLl8xNTJzenlxNCB7CiAgZmxleDogMTsKfQouXzE1MnN6eXE1IHsKICBtYXgtd2lkdGg6IDUwJTsKICBmb250LXNpemU6IHZhcigtLWFmZmluZS1mb250LXNtKTsKICBvdmVyZmxvdzogaGlkZGVuOwogIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzOwogIHdoaXRlLXNwYWNlOiBub3dyYXA7CiAgY29sb3I6IHZhcigtLWFmZmluZS12Mi10ZXh0LXRlcnRpYXJ5KTsKfQouXzE1MnN6eXE2IHsKICBtYXgtd2lkdGg6IDExMHB4OwogIG1pbi13aWR0aDogNjBweDsKfQouXzE1MnN6eXE3IHsKICBwYWRkaW5nOiAwIDJweDsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZ2FwOiA0cHg7Cn0KLl8xNTJzenlxOCB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogNHB4OwogIG92ZXJmbG93OiBoaWRkZW47Cn0KLl8xNTJzenlxOSB7CiAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7CiAgd2hpdGUtc3BhY2U6IG5vd3JhcDsKICBvdmVyZmxvdzogaGlkZGVuOwogIGRpc3BsYXk6IGlubGluZS1ibG9jazsKfQ==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cell = '_152szyq2';
export var cellList = '_152szyq7';
export var databaseName = '_152szyq9';
export var databaseNameWrapper = '_152szyq8';
export var divider = '_152szyq3';
export var docRefLink = '_152szyq5';
export var mobileDocRefLink = '_152szyq6 _152szyq5';
export var root = '_152szyq0';
export var section = '_152szyq1';
export var spacer = '_152szyq4';