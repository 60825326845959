import "../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62T3VKDMBCF7/sUubQX6UDFqunDOAEWWA0Jk2wBdXx3J6FaoL/OeEWybHbPd7JZvcT9porbNmKfC8YqwLIiweIoaqvtgrEOc6qGfef3ObpGyXfBCgW9D7zuHGHxzjOjCTQJloEmsP6XVFhqjgS1G4cLo4k7/ADBWmnvOJdFgRp4iKfSwdJnNcYhodGCWVCSsIXt4mux+pEbB7mZUcYKhroCizRJWIeEQxWZOqN2BL42mUawyK8UFLRf2oE8Okl5BuUI3jUyA54CdQA6YMg8R10KFm+ansXrqOknMu+DzKN+mVG7WvNSNoIlsyPJGH3iIEFPvLFYS+s1KWOXVw139SGn29/+QxRcCOVyyIyVg4faaJhAJZ7paabv4TqSN2NyZnOWSaF+m7PcqvM6+EjC42nZ/stztJANtQeMCzNRS1ui5mHGkvl1P4Um45RhIn5jqSEytWDxenbyOZz8E/6tdt7qkPwnh6zp9mOwHthPv9IfK5L5gKWndZzpNnTau3yRt3fLc7YFjx1kRufjt/X1DcuuUBs/BQAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = '_1x6h1vv1';
export var centerContent = '_1x6h1vv7';
export var docFooter = '_1x6h1vva';
export var docFooterText = '_1x6h1vvb';
export var editSettingsLink = '_1x6h1vv9';
export var prompt = '_1x6h1vv8';
export var promptLink = '_1x6h1vv6';
export var promptLinks = '_1x6h1vv5';
export var root = '_1x6h1vv0';
export var topNav = '_1x6h1vv2';
export var topNavLink = '_1x6h1vv4';
export var topNavLinks = '_1x6h1vv3';