import "../../core/src/desktop/dialogs/setting/general-setting/experimental-features/index.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/general-setting/experimental-features/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUzXLbIBC++ym4dKY5kJEd1Unx0yBYydsg0MDKUprJu3dAjS0Uq2NPTx6zq4/9fpZHXTyXzalg7xvGOheQ0FnBZBWc6QkOG8Y0hs7IN8FqA2M8iL9cowc1NStn+tbGyhGwOZJg26L4dth8bB4n9G1Cr50lHvA3CHaS/jvnsq7RAk/nR14+HD6bhr8w+6KIZ630DVpeOSLXCla+dOMMfJfAFz37vOcp9VRSvTbe9VZz5YzzizlmZQJPKP3b1HcZbG36MTzcqdSvPhDW8QJLYEkwBZbAx1IjO8G2ZZcQOqk12iZKOh1Uzmvw3EuNfRAs51kmntfIgffOL+lkOp9BfkxuxdEb7wbBtskEtPxs7y67dZ8++EJeGmwsR4I2zOn9g/nCxKfdRDkJUmZXPl+/cl3vhLHLQV6ug6wOeP7w5/8RDp1UwCugASANN6Cm45etkZetWSzENYMJRuIBlLM6D+5C1Dww1b06rnCcMltk2Go1jKuz3rJk7gS+NjGXR9R6EjABXgpgDHYBQ7aTfIDqFYlXLlH8/GsivjKyjfmYFyo3cucxOXaK74GSZt0snchmtdsTcgvtu3RMduROw/yZNFCTYLInN0uInzIWn4I/jFDtLxYGAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRightIcon = 'd074gve';
export var description = 'd074gvc';
export var feedback = 'd074gvd';
export var promptArt = 'd074gv2';
export var promptDisclaimer = 'd074gv6';
export var promptDisclaimerConfirm = 'd074gv8';
export var promptRoot = 'd074gv0';
export var promptTitle = 'd074gv1';
export var promptWarning = 'd074gv3';
export var promptWarningTitle = 'd074gv4';
export var rowContainer = 'd074gvb';
export var settingsContainer = 'd074gv7';
export var spacer = 'd074gv5';
export var subHeader = 'd074gva';
export var switchRow = 'd074gv9';