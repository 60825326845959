import "../../core/src/components/mobile/config-modal/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/mobile/config-modal/styles.css.ts.vanilla.css\",\"source\":\"Ll80MDA5YzEwIHsKICBwYWRkaW5nOiAwOwogIG92ZXJmbG93LXk6IGF1dG87CiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tYWZmaW5lLXYyLWxheWVyLWJhY2tncm91bmQtc2Vjb25kYXJ5KTsKfQouXzQwMDljMTIgewogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdjItdGV4dC1wcmltYXJ5KTsKICBkaXNwbGF5OiBpbmxpbmUtZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogOHB4Owp9Ci5fNDAwOWMxMyB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogOHB4OwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdjItdGV4dC1wcmltYXJ5KTsKfQouXzQwMDljMTQgewogIHBhZGRpbmc6IDI0cHggMTZweDsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZ2FwOiAxNnB4Owp9Ci5fNDAwOWMxNSB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGdhcDogMTZweDsKfQouXzQwMDljMTYgewogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdjItYnV0dG9uLXByaW1hcnkpOwp9Ci5fNDAwOWMxNyB7CiAgd2lkdGg6IDEwMCU7Cn0KLl80MDA5YzE4IHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZ2FwOiA0cHg7CiAgd2lkdGg6IDEwMCU7Cn0KLl80MDA5YzE5IHsKICBjb2xvcjogdmFyKC0tYWZmaW5lLXYyLXRleHQtdGVydGlhcnkpOwogIHBhZGRpbmc6IDRweDsKfQouXzQwMDljMWEgewogIGJhY2tncm91bmQ6IHZhcigtLWFmZmluZS12Mi1sYXllci1iYWNrZ3JvdW5kLXByaW1hcnkpOwogIGJvcmRlci1yYWRpdXM6IDEycHg7CiAgcGFkZGluZzogNHB4OwogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBnYXA6IDhweDsKfQouXzQwMDljMWIgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBnYXA6IDhweDsKICBwYWRkaW5nOiA4cHggNHB4Owp9\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottomDoneButton = '_4009c17';
export var doneButton = '_4009c16 xn1iiob';
export var group = '_4009c18';
export var groupContent = '_4009c1a';
export var groupTitle = '_4009c19 xn1iiom';
export var pageContent = '_4009c14';
export var pageModalContent = '_4009c10';
export var pageTitle = '_4009c12 xn1iiob';
export var popupContent = '_4009c15';
export var popupModalContent = '_4009c11';
export var popupTitle = '_4009c13 xn1iiob';
export var rowItem = '_4009c1b';