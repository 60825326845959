import "../../core/src/desktop/dialogs/doc-info/info-modal.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/doc-info/info-modal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41Ty4rbMBTd5ysuA4XMQoPsuOlUQ6GfUShdKJZsC2RdISmO05J/L5afctuhO+va97x0/NJXwhYthV8HgJb35KZEaBgUr9T2b8NMmXl2Ok8zy4VQpmaQU9sDHUZcq9oQL3XFwAfuQtzlrlaGBLQMsjwuPw4vI2MWGYXyVvM7g0rLCD1RZZR+GI7DmAjlZBkUGgYl6mtrNtgXDAHbUUkqLWHLI1uFJhCvfkoGHXdHQnhVKSNJnDckf36bP7pJVTeBwZnSOYRmGhX5jolueE5/d7W34fA2jGtuGZx3cNN5DuKcxlYsFzXrKbkujxmlXQME8iGH51Qe5EUC8TFCTPgTW3Dc+Apdy8ZHzYP8diRFBFs2z9vNGMwsIh4sejX64xeP+hrkZvfTJHxtxHxlF3RCuuUmU7Gv/xfo2otVhJOaB9VtRXyOaNuuRqClsO8WxLcx2BI1ut37LidB9oF4WaIR3N33V1CMVue4TlPsSdeKsWtrKR6H74IHTqxDK124kxK15tZL8eUpuKt8+gGJryUlgyZ17bt6/wNkk4R/2FElmsTOisYa7KRbMN8DsE618/rXVgrFwTplQlxMtP+hHuBxePwGMVfKi54EAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addPropertyButton = 'xfdp4m9';
export var container = 'xfdp4m0';
export var hiddenInput = 'xfdp4m6';
export var rowNameContainer = 'xfdp4m3';
export var scrollBar = 'xfdp4m5';
export var tableBodyRoot = 'xfdp4m8';
export var timeRow = 'xfdp4m7';
export var titleContainer = 'xfdp4m1';
export var titleStyle = 'xfdp4m2';
export var viewport = 'xfdp4m4';