import "../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WbW/aMBD+3l9hbZoEEkYhhY660qTuw/6HEx/BrbEj2xTo1P8++SWJQ4CWaqqEFNk+7nl899ydp0/LV16xDP29QahWhluuJEEaBLX8BR5uEGLc1IIeCFoJ2LsN98WMayiDcanEdiPdyY4zuyZolmU/3HINvFrbZv12Mw1gMw82cPu0NZavDrhU0oK0BJmaloALsDsAmfpb5LX/R00Z47JyAPUeLcNmQcvnSqutZAS9UD3CmK5WXAJ+ybGgB9C4s8C15huqD2N/LSUtNvwVCJpFAL+1i6jzLHN7pRJKD11b2FtsoFSSNQ4LpRlobFWNY2Cy6SKS7I6MPQggyCjB2dHRGahwCy4NZ/A7WIc/eVRPZKdpTZBU7us21QvolVA7gtacMRfNNhu5z8YFgxn6hXq2g8xVDqyJGRW8kphb2BiCSpAWdJIrLGBlO+NmV0eh+O2O2vRVqQ2Ryo6mZq124z68VBIS69vT5IY3izHqDkAIXhtuvITX3AL20iOo1r4CdkqzGNJCA33GbiNBnnvk82VxJiQDwXdHPqLzXjAWASQR6eKESBdBpMJJpSmXfB4MLyk3LYQN1RWXToGDjNwNSJyqlJMksvdJWNCWBxYt4s/TWT0f7CDGiJZeJV/2rrK80PJeMZcM9gRlZxsgQbNz6tIQYnOkzyUpYKU0eNw259++PfRo0MIosbWehqftKcT6yEKDsFZt4iKUU9YjjWc9VF8+lea+0sYpiaQRXmw1iV3a4TqM1n3qXdW05Pbgul6+6HfmKB6niVYYySnf0AqI1w/VuNKUcZB2dEyu2FqrpDsvCtCYwYpuhR2jWb2fIKupNDXVIK3bGE8G7qwKYZ0Mbn2V4yQO9/7evSHYntHTOv5oaxjMwnb2Zcc1WnikDd03U6ekohw5OgijeeYpf2RM+k4YB9neZczDFc3ASedYc+XecDsz2D411DyDNWWu0Jxo5lE4fhFcJXnBhaDlM86zbIJ0VdBRNkHxN51l4/E7E7G8tuWcrt7zUWvC1Ui/e8fk7kpJwd82xRFr/rbta/u2s6bpvWvTGwCd1reGtG+jr0rX9Y3lmpfYZ+fLyebF/s/TN81I8/69NBwKZdcpi2mphKC1AXbxkcNCO2+Nx7HY/ZhrVNIfdPDxd9GRauZfqZqO79SAgNLGSPQ9fs/vH2//PD7cvP0DzdHZcboMAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var error = 'j8zigd4';
export var errorBtns = 'j8zigd7';
export var errorMessage = 'j8zigd6';
export var errorTitle = 'j8zigd5';
export var pdfIndicator = 'j8zigda';
export var pdfPage = 'j8zigdb';
export var pdfThumbnail = 'j8zigde';
export var pdfThumbnails = 'j8zigdc';
export var pdfThumbnailsList = 'j8zigdd';
export var titlebar = 'j8zigd1';
export var titlebarChild = 'j8zigd2';
export var titlebarName = 'j8zigd3';
export var viewer = 'j8zigd8';
export var viewerContainer = 'j8zigd0';
export var virtuoso = 'j8zigd9';