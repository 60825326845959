import "../../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71VYW/bIBD9nl9xilQpWUtqJ03bUe2XTNOEDU5QCVgYd0mn/ffJYNeAHauJpn2z4e7eu+Pd3epn+lBv9infwO8ZAELdf4LhuTy+zABKVXHDlcRAskqJ2rDm1KgSQ9J8ab7bm/ZbsMJgeCN60UdKl81NpoxRh9asVFwaphF7Y9JUGKSS7GX2Z7b6YINxxgqlmWWVK2mYNBjm88ab8qoU5IQhEyp/PU/xHXFJ2RHD2hE4ooq/c7nDkClNmUaZsgn+4tTsY9aJZb1nLrmxO5erzScj+etOq1pSDEYTWZVEM2kcrIXKlVA6jrNeehYti7UrenuoCeV1hWGb3HinlTkJhqFSgtO4bKQwTP+TqqVxZmPkXRFyIvJFXCO4h3VQxDa1Xi8+8++UGIKkQobpA5dEfJsbXbP5j0AJH0kMFPMQ2PXqtM9RKH1oX0YQwxaWL0pWW/gyeNrlHUxeL2PYvuIWFaVNmp7JNmAW9MHV5D7Fbetz63AH9B7t9X9r8k7EVg5OwlNd0TXAJmL9ZFlHypoQ472LOqq9p+CBvCAoHSty4PtsfcLetVWbYuGVyQ81VPBnWZDeJTT8GtldAgG3kJbHMEBmHfXYUIygsgCq9bgMLA+EO4mWj/bYZXDUe/5JMHqRXsag2HDZPjrtXDelz7baYHqTouCSoVLzA9Ent5YiblOrtxV64o/1ZHq7jvPvhsCgWv5EOEN2fDv6KVy3TP4C0z2SSQwJAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottom = '_14u3h1ic';
export var edgeLine = '_14u3h1ie';
export var horizontal = '_14u3h1i7';
export var horizontalIndent = 'var(--_14u3h1i1)';
export var indicatorColor = 'var(--_14u3h1i2)';
export var left = '_14u3h1id';
export var lineAboveStyles = '_14u3h1i4';
export var lineBelowStyles = '_14u3h1i5';
export var localLineOffset = 'var(--_14u3h1i9)';
export var outlineStyles = '_14u3h1i6';
export var right = '_14u3h1ib';
export var terminalSize = 'var(--_14u3h1i0)';
export var top = '_14u3h1ia';
export var treeLine = '_14u3h1i3';
export var vertical = '_14u3h1i8';