import "../../core/src/desktop/dialogs/setting/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VX7W7bIBT936dAkya1UolwmmQbfYw9ATbXNi0BBKRxNu3dJ8B2sOO029r9iWQ+7se55x5uVs+mrNUDQT9vEOLCGclOFNUSuscbhJgUjcLCw96lRQyKh409s41Q2GtDUbE23ePNr5tVMlVEU/FwY/WRouLxTdMVKA82Myyh9hStycTyOlo+Cu5bigpCPv+D4RjxemPi0YaF8En6iBEfbVgKv5nfh9zvdpeOtyCa1p+/S205WGwZFwdH0TZFZ7QTXmhFkQXJvHiBsFodrNOWIqPFEF707lor1DNFJHe+4sKxUkIMYrjJoWYH6ZOLaATDCyjvKFJaQX6ftvoFLFpVbA+WYVFpFdM0YJeK/gEXr195J4asdFoePEzqnvJF6OngvKhPuNLKg/J57a9QomTVc2P1QXFcaRlgtU3JbnfkHu2Ke7R7uEdktb0LR39goTh0PZn70y/M3mLM6loowMdWeIhna608duIHDEQbkdnkIFRMVreBxQij3cZ0CKNia7q7xVxCP4QND53HMZ1hLTPuDFNLLRLKWMvQiq3gHNRo6LwBUgrjhAtbMRPsDKsgoJuaYTlpI1kFrZahanH/DMBxqC3J2byN8S13hTasEv5EEVltljv2bIZSVvueVCNInz49XiY/cGz4XmZT0hsSkQn+SN6mSgfMpT4Cz2LYLUvmn9IwnP/umfXXsI0VclBpxZk9ZfBG1eoVzDDOhWoo+tqL2Jl7E2NxvXN3WfxfUikGA5vFFkzanrfKzHDWQ8aK/TTSZIyiwnTIaSn47G7ydT5/AWZURS4sVKlklZaHvVqEOfIVl+CPEBg+pvltmiYJiSKCirkE9QBehDCj06KUnJ31mvnzNcjikQuURsi/Tpj+jSrtb6lkzmNdY38ycBfN991Rau/1nqLd5BbLZWC9JVdyuw7v/DmaPcwDLfpFmzCcPdfl4iDwMWL0Ks/dPud5uTKgQvF7sVhQsRlxx6tVyuBNX6808GVTfAwAMjKpJ+96OoHxt8OOUvCXuvM/AiczsvcjR/pMI8/4LBRL0w6TMjNQ/5/MZ722nQTd/NFUejFd/tPAMlWK3ewNWJtunGbfkN5UnAcyH1j+Hq9Lgi9U+AobRgzb99cN9qZlTrgskIUxe6Jim5ksChVCz8IS0yc+7eNX/2iMd5/mKQ0vzrsy+PUbeD578LANAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRight = 'kpbfn3j';
export var avatarWrapper = 'kpbfn33';
export var fakeWrapper = 'kpbfn35';
export var goUpgrade = 'kpbfn3h';
export var goUpgradeWrapper = 'kpbfn3i';
export var iconButton = 'kpbfn3e';
export var label = 'kpbfn3f';
export var labelWrapper = 'kpbfn32';
export var memberContainer = 'kpbfn3a';
export var memberEmail = 'kpbfn3d';
export var memberList = 'kpbfn38';
export var memberListItem = 'kpbfn39';
export var memberName = 'kpbfn3c';
export var membersFallback = 'kpbfn36';
export var membersPanel = 'kpbfn37';
export var profileHandlerWrapper = 'kpbfn31';
export var profileWrapper = 'kpbfn30';
export var roleOrStatus = 'kpbfn3b';
export var urlButton = 'kpbfn34';
export var workspaceLabel = 'kpbfn3g';