import "../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/bIBD+3l+BKlVqpBI5r8uItv8xTdNE4GyzEvAAN+mm/fcJsBPs2M1aVdq3+HLcPffcwx3T77MF/DxSnqHfNwhxYStJnwnKJRy3NwgxLbUh6Imae4xpngsF2MHR4cqIPTXPODhMvGsJoigdQatlFY76ENiWRqhHgjJvOQjuSoJmWXYXYytHhSLIOiOY8yYqRaGwcLC3wQyOld7uDFVWOKEVQTvKHguja8VjcpRN5/YB6Yoy4Z7Dlz+in8DkUh8IKgXnoELG2lhfDoec1tJFTFJiVlJVAGljbG/+3ExPxJDShwr09FP3iAmOJ0bSGF85dRQzKdgj3Un45EwN30LIFlKlhXJgOsdmwaPldbmKvO604WCwoVzU1rMZzRet63DJIEZPaxhHj3MhJfBJTOe7SLlnsuO/B1U3/wTHPT3ipsOLdQNqL1Rrm0dbUt48lFfptrEGJHXiCWIwUwiFJeSOILyJ0VomWgHp2kmhgCClVThVUc6FKppjm5gvcs8NLYqE+zMKsoNcG4jd0MqBcgTd3m472OjOalm7kMXpiqBVhBCEmWuzJ/GnpA6+3ONVdhc4iUBS8S+7pTSfqb7jXyOqvqLASlIGpZY80eGFYuYxZxP6BC+5BxHCQ3ofrrIY5JNyeepWP99sNF5iFKq4yBHCnmFPV28NNARz0cBs5ToE+nyh0WdEcmGsw6wUkkcld9Q3W/fkHs5IOnjERAyb3pFF6kTQ6nU3vaBVOx6SmMvhQT8S43KI51o5bMUv6Ekv2Eu86E6+1T9kizkcNW6bVps1FHYmC6OS3fv7jzBaL6vjpAUZOoTQoRQOsK0oC2PhYGjlzbUFgy1IYK6dFgnIdRTWwMJ4sVi7n5x8Do2K1ln2AoywOc95QEpRWWHPjFiQeUp/Tx/LXi8/jAIf3dsWmFa8u7lfrHJHLUyGpfA+VSb1bF6nzetKHCZCMK3S8nuFJYA+vh+gox3FM9qY5C70VmC7YDtPqgQ4/b/AB8aGt3iFpErp15cUsHtVAT9q60TuETT7O6QDxa/0l71fFqZlvVc4DN318As4SRzHf7treo+C9vvNuAauFqQJe2LaDD4smxfORRF/ARquJ5kzDAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_13eqxadb';
export var dateCell = '_13eqxada';
export var dndCell = '_13eqxad2';
export var dragPageItemOverlay = '_13eqxad1';
export var iconCell = '_13eqxad8';
export var operationsCell = '_13eqxadc';
export var root = '_13eqxad0';
export var selectionCell = '_13eqxad4';
export var tagIndicator = '_13eqxade';
export var tagIndicatorWrapper = '_13eqxadd';
export var tagsCell = '_13eqxad9';
export var titleCell = '_13eqxad5';
export var titleCellMain = '_13eqxad6';
export var titleCellPreview = '_13eqxad7';
export var titleIconsWrapper = '_13eqxad3';