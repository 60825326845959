import "../../core/src/components/tags/tag-edit-menu.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/tag-edit-menu.css.ts.vanilla.css\",\"source\":\"Lm9tNHMzbjEgewogIHRyYW5zZm9ybTogdHJhbnNsYXRlWCg0cHgpOwp9Ci5vbTRzM24yIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgbWF4LWhlaWdodDogMjAwcHg7CiAgb3ZlcmZsb3c6IGF1dG87Cn0KLm9tNHMzbjJbZGF0YS1yYWRpeC1zY3JvbGwtYXJlYS12aWV3cG9ydF0gPiBkaXYgewogIGRpc3BsYXk6IHRhYmxlICFpbXBvcnRhbnQ7Cn0KLm9tNHMzbjMgewogIHdpZHRoOiAyMHB4OwogIGhlaWdodDogMjBweDsKICBkaXNwbGF5OiBmbGV4OwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLm9tNHMzbjQgewogIHdpZHRoOiAxNnB4OwogIGhlaWdodDogMTZweDsKICBib3JkZXItcmFkaXVzOiA1MCU7Cn0KLm9tNHMzbjUgewogIHdpZHRoOiAyMHB4OwogIGhlaWdodDogMjBweDsKICBib3JkZXItcmFkaXVzOiA1MCU7Cn0KLm9tNHMzbjYgewogIGhlaWdodDogYXV0bzsKICBib3JkZXItcmFkaXVzOiAxMnB4Owp9Ci5vbTRzM243IHsKICBjb2xvcjogdmFyKC0tYWZmaW5lLXYyLWJ1dHRvbi1lcnJvcik7Cn0KLm9tNHMzbjggewogIGZsZXg6IDE7Cn0KLm9tNHMzbjkgewogIG9wYWNpdHk6IDA7CiAgY29sb3I6IHZhcigtLWFmZmluZS12Mi1idXR0b24tcHJpbWFyeSk7CiAgd2lkdGg6IDIwcHg7CiAgaGVpZ2h0OiAyMHB4Owp9Ci5vbTRzM245W2RhdGEtc2VsZWN0ZWQ9InRydWUiXSB7CiAgb3BhY2l0eTogMTsKfQ==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var menuItemList = 'om4s3n2';
export var menuItemListScrollable = 'om4s3n0';
export var menuItemListScrollbar = 'om4s3n1';
export var mobileTagColorIcon = 'om4s3n5';
export var mobileTagEditDeleteRow = 'om4s3n7';
export var mobileTagEditInput = 'om4s3n6';
export var spacer = 'om4s3n8';
export var tagColorIcon = 'om4s3n4';
export var tagColorIconSelect = 'om4s3n9';
export var tagColorIconWrapper = 'om4s3n3';