import "../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WbW/aMBD+zq+wkCa1H1wF2tFiVGn/Y6qmw76EG44d2Q4tnfrfpzgJJCFQpu4TxL7X5567848t7lMHOXr2a7aXxWL3umZ/Jowl3+IPY7YASWEv2Gw1Yexjwtjiwt3j6V3S3j1d0Fte0JslZxU/Jndt2LMoIq22TjAyG3QUVl2BeRRQ5AsN+0pEk0GeanyrbIGmzHAKmHvBJJqArjpeW6fQcQeKSi/YQxGFA74FHjUE05iG1Yhnxl5JhY2I4VefORm+Qco2QbD7pDZUenTco0YZBDPWYLRUOl+ZKiy1YRSgFJlMsKQNIbUmcE/vKNgO3A3nkKYxn3ie30Yt6ymQNYI51BBoF83n4DIyPNhCsBhGFyOxsTt0Eak1yG3mbGnUwEUU4THf2772TwUBOMjK1fM0uBKnL1+zpcjDWqPqWWsBUphCqbvo92zHKnmU1ihw+4MT1gLLcYcm+Bb4EecOsoxM1nN+5Ofd957SfZ9fnxCrIUckeSXaUHq8ZBkUgs3mg2I9RIfjyXQtwdpbXYZ4WrG18RoJwGcNEw9krT9bos6GDPleg7BDl2r7KtiGlEJzaIrjBWpNhScfjW8oIPcFSKwCfHVQXICmA8dYWXfzurKFoxzcPlY0tnIbc0+6c9Mn2OKfyuVq08l5ZPtz63OKNZ02iKc/4IZWyFAg0P0gDocd64/jyf0ufaC0agUT0IRugpdpOh/Qov0+Ux+S1vTq05lW8yGjnmKsLf8WA/4139fW6UKGg1E+b0a5A9MiGf+n1uUsuZv76/PrJlNPDmm1hsIP5tbBvmDOBgh4w5eJwmzUxOjk6wyf+6to9vSlgb48hj0KEUPwyMlwW/Z37fLrMECfwieZgbBG77nckFbDzX7aEDKKdFdfU/9ms/J6MNawtErJ2S3asawuY3u8aAnTWUSH7mraaUDRxaBTsBn4F+PBGvrgsKqMD66UlfTzNIct1nBNX2oZjzqtlxyqOLmfpyloj9MXAWloWHPopOm014hrbeX2/Dh852TUYY5fSb12P42VoX6vdFCToOVNfBvyE4Xb/g6rHmD/G6L63hZoxlADQznUqBzf1TPPyKQVPTFuLHCrycdfy937aIILAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1ycp6vwa';
export var collapsedIcon = '_1ycp6vw9';
export var collapsedIconContainer = '_1ycp6vw8';
export var contentContainer = '_1ycp6vwc';
export var draggedOverEffect = '_1ycp6vwe';
export var draggingContainer = '_1ycp6vwd';
export var iconContainer = '_1ycp6vw7';
export var itemContent = '_1ycp6vw5';
export var itemMain = '_1ycp6vw3';
export var itemRenameAnchor = '_1ycp6vw4';
export var itemRoot = '_1ycp6vw2';
export var levelIndent = 'var(--_1ycp6vw0)';
export var linkItemRoot = '_1ycp6vw1';
export var postfix = '_1ycp6vw6';